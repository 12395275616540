import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@components/Container';
import Layout from '@components/Layout';
import Paragraph from '@components/Typography/Paragraph';
import Heading from '@components/Typography/Heading';
import GradientBtn from '@components/Buttons/GradientBtn';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { DEVICE } from '@assets/const';

const { MD, LG } = DEVICE;

const CustomMDXRenderer = styled.div`
  p {
    line-height: 1.5;
  }

  ul {
    margin: 0 0 2rem 0;
  }

  li {
    margin: 0 0 0.5rem 0;
    line-height: 1.5;

    &:first-child {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    :not(:first-child) {
      :before {
        content: '■';
        display: block;
        float: left;
        width: 1.2em;
        color: #bd8604;
      }
    }
  }
`;

const ArticleGrid = styled.div`
  margin: 2rem 0 0 0;
  padding: 0 1rem;
  display: block;

  @media ${MD} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 5rem 0 0 0;
  }
`;

const Article = styled.article`
  grid-column-start: 1;
  grid-column-end: 4;
  @media ${LG} {
    grid-column-end: 3;
  }
`;

const ButtonBox = styled.div`
  margin: 3rem 0 0 0;
`;

// const ArticleImage = styled.img`
//   display: none;
//   @media ${LG} {
//     display: block;
//     width: 100%;
//     height: auto;
//   }
// `;

const BlogPost = ({ data }) => {
  const { body: content } = data.mdx;
  const { title, date } = data.mdx.frontmatter;

  return (
    <Layout>
      <Container>
        <ArticleGrid>
          <Article>
            <Heading mbottom={0.1}>{title}</Heading>
            <Paragraph gray fsize={0.9} mtop={1} mbottom={1.5}>
              <i>{date}, by GBP Office</i>
            </Paragraph>
            <CustomMDXRenderer>
              <MDXRenderer>{content}</MDXRenderer>
            </CustomMDXRenderer>
            <ButtonBox>
              <GradientBtn url="/aktualnosci" text="Wstecz" />
            </ButtonBox>
          </Article>
          {/* <ArticleImage src="https://excelraport.pl/wp-content/uploads/2018/01/wykres-procentowej-zmiany-warto%C5%9Bci.png" /> */}
        </ArticleGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
      }
      body
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
      }),
      body: PropTypes.string,
    }),
  }).isRequired,
};

BlogPost.defaultProps = {};

export default memo(BlogPost);
