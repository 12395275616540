import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Button = styled(Link)`
  display: inline-block;
  padding: 1rem 3rem;
  border: 3px solid #bd8604;
  //border-image-slice: 1;
  //border-image-source: linear-gradient(to right, #bd8604, #e7b80c, #f4d469, #e7b80c, #bd8604);
  //border-width: 3px;
  transition: 0.3s;

  &:hover {
    border-color: #e7b80c;
  }
`;

const GradientBtn = ({ url, text }) => <Button to={url}>{text}</Button>;

GradientBtn.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
};

GradientBtn.defaultProps = {
  url: '/',
};

export default GradientBtn;
